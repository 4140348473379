import React from "react";
// import "../../docs.css";
import DocUpow from "../DocUpow";
import { Helmet } from "react-helmet";

function UpowDocs() {
  return (
    <main className="docs">
      <Helmet>
        <title>Docs - uPow.ai</title>
        <meta
          name="description"
          content="Comprehensive uPow blockchain guide: Create wallets, run iNodes, Validators, Miner Pools, and AI Miners. All steps detailed for seamless setup."
        />
      </Helmet>
      <DocUpow />
    </main>
  );
}

export default UpowDocs;
