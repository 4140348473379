import { Container, Row, Col} from "react-bootstrap";

import "animate.css";
// import TrackVisibility from "react-on-screen";
// import { AiAppCard } from "./AiAppCard";

// import imgCore1 from "../img/core1.jpg";
// import imgCore2 from "../img/core2.jpg";
// import imgCore3 from "../img/core3.jpg";

// import imgInode1 from "../img/inode1.jpg";
// import imgInode2 from "../img/inode2.jpg";
// import imgInode3 from "../img/inode3.jpg";

// import imgVal1 from "../img/val1.jpg";
// import imgVal2 from "../img/val2.jpg";
// import imgVal3 from "../img/val3.jpg";

export const AiApps = () => {
  // const projects = [
  //   {
  //     title: "Blockchain's Heart",
  //     description: "Central hub of blockchain, ensuring network integrity.",
  //     imgUrl: imgCore1,
  //   },
  //   {
  //     title: "uPow's Backbone",
  //     description: "Symbolizing the core layer as uPow's digital backbone.",
  //     imgUrl: imgCore2,
  //   },
  //   {
  //     title: "Connectivity",
  //     description: "Blocks depicting decentralized connectivity.",
  //     imgUrl: imgCore3,
  //   },
  // ];

  // const inode = [
  //   {
  //     title: "Customization Hub",
  //     description: "Tailor your Inode, shape your blockchain role.",
  //     imgUrl: imgInode1,
  //   },
  //   {
  //     title: "Unique Node Identity",
  //     description: "Distinctive nodes, powering personalized incentives.",
  //     imgUrl: imgInode2,
  //   },
  //   {
  //     title: "Incentive Core of uPow",
  //     description: "Drive growth with custom Inode incentives.",
  //     imgUrl: imgInode3,
  //   },
  // ];

  // const validators = [
  //   {
  //     title: "Network Sentinels",
  //     description: "Guarding uPow's integrity with vigilant oversight.",
  //     imgUrl: imgVal1,
  //   },
  //   {
  //     title: "Vote Power",
  //     description: "Empowering uPow with democratic validator votes.",
  //     imgUrl: imgVal2,
  //   },
  //   {
  //     title: "Oversight in Action",
  //     description: "Validators ensure fairness and transparency.",
  //     imgUrl: imgVal3,
  //   },
  // ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            {/* <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                > */}
                  <h2>
                    uPow: Revolutionizing Blockchain with a Hybrid Consensus
                    Mechanism for Practical Computational Tasks
                  </h2>
                  <p>
                    uPow redefines blockchain with a unique consensus mechanism
                    inspired by PoW, DPoS, and PoA. It focuses computational
                    power on practical tasks, not just puzzles, for a more
                    efficient and purposeful blockchain application.
                  </p>
                  {/* <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">CORE</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">INODE</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">VALIDATORS</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <AiAppCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {inode.map((project, index) => {
                            return <AiAppCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {validators.map((project, index) => {
                            return <AiAppCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
      <div className="background-blur-left"></div>
      <div className="background-blur-right"></div>
    </section>
  );
};
