import React from "react";
import "animate.css";
// import Img1 from "../assets/Unlimited-transactions.json";
// import Img2 from "../assets/Virtual-machine.json";
// import Lottie from "lottie-react";

function Para() {
  return (
    <div>
      {/* <div id="pxp-cta-3-first" className="pxp-cta-3 mt-100">
        <div className="container">
          <div className="row align-items-right">
            <div className="col-lg-5">
              <div className="pxp-cta-3-image pxp-cover rounded-lg">
                {<Lottie animationData={Img2} />}
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-10 animate__animated animate__fadeInRight">
              <h2 className="pxp-section-h2 mt-3 mt-md-5 mt-lg-0">
                Pioneering Blockchain for AI Evolution
              </h2>
              <p className="mt-3 mt-lg-4">
                At Upow, we're revolutionising the blockchain landscape to
                harness its potential for AI development. Our unique approach
                redirects computational efforts towards meaningful AI tasks,
                fostering an ecosystem where innovation thrives. By integrating
                advanced consensus mechanisms, we ensure that every computation
                contributes to the broader horizon of AI, setting a new
                benchmark for technology symbiosis.
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div> */}

      <div id="pxp-cta-3-second" className="pxp-cta-3 mt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 animate__animated animate__fadeInLeft">
              <h2 className="pxp-section-h2 mt-3 mt-md-5 mt-lg-0">
                Beyond AI: The Versatile Upow Blockchain
              </h2>
              <p className="mt-3 mt-lg-4">
                At Upow, we're revolutionising the blockchain landscape to
                harness its potential for AI development. Our unique approach
                redirects computational efforts towards meaningful AI tasks,
                fostering an ecosystem where innovation thrives. By integrating
                advanced consensus mechanisms, we ensure that every computation
                contributes to the broader horizon of AI, setting a new
                benchmark for technology symbiosis.
              </p>
              <p className="mt-3 mt-lg-4">
                Upow transcends the conventional boundaries of blockchain
                applications. While our platform stands as a cornerstone for AI
                advancements, its architecture is designed for limitless
                possibilities. From secure data transactions to decentralized
                finance, Upow is crafting a future where blockchain is the
                backbone of diverse technological frontiers, redefining
                versatility and innovation in the digital age.
              </p>
            </div>
            {/* <div className="col-lg-2"></div>
            <div className="col-lg-5 order-first order-lg-last">
              <div className="pxp-cta-3-image pxp-cover rounded-lg">
                {<Lottie animationData={Img1} />}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Para;
