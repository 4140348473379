import React from "react";
import { IoLogoGithub } from "react-icons/io";
import { SiDiscord } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../img/U-crypto-logo.png";

function AboutUpow() {
  return (
    <div className="pxp-content">
      <div className="pxp-blog-posts pxp-content-wrapper mt-100">
        <div className="blog-post-heading">
          <div className="container">
            <div className="pxp-blog-post-category">
              {/* <span>December 9, 2023</span>
              <span>Learn about us</span> */}
            </div>
            <h1 className="pxp-page-header">What is uPow?</h1>
            <p>
              uPow is a layer 1 blockchain that uses proof of work (PoW) for
              block generation. What exactly is uPow? It stands for
              <b> “Useful Power of Work.”</b> But what does that mean? Simply
              put, uPow was crafted with the mindset of contributing
              computational power for useful work — think machine learning,
              medical research, and many other fields.
            </p>
            <p>
              It represents a groundbreaking initiative in the blockchain space.
              Our mission is to address real-world challenges by harmonizing
              blockchain technology with artificial intelligence, machine
              learning, and data training. We're redefining the blockchain
              landscape, focusing on practical, impactful solutions.
            </p>
          </div>

          <div className="pxp-blog-post-hero mt-4 mt-md-5">
            <img
              src={Logo}
              style={{ width: "400px", height: "400px" }}
              alt=""
            />
          </div>
        </div>
        <hr className="seperator" />
        {/*  */}
        <div className="pxp-blog-post-main">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-sm-12 col-lg-1">
                <div className="pxp-blog-post-share">
                  <div className="pxp-blog-post-share-label">LINKS</div>
                  <ul className="list-unstyled mt-3">
                    <li>
                      <a
                        href="https://discord.gg/f2Vy5SpdB2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{<SiDiscord />}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://github.com/upowai"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{<IoLogoGithub />}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Upow_ai"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{<FaXTwitter />}</span>
                      </a>
                    </li>
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-10 mb-5">
                <div className="pxp-blog-post-block mt-4 mt-md-5 mt-lg-0">
                  <h2>Introduction </h2>
                  <div className="mt-3 mt-md-4">
                    <p className="pxp-first-letter">
                      uPow adopts a distinctive approach to the conventional
                      blockchain. Rather than utilizing computational power for
                      solving arbitrary puzzles, we channel this power into
                      accomplishing useful tasks. We've developed a consensus
                      mechanism that draws inspiration from PoW (Proof of Work),
                      DPoS (Delegated Proof of Stake), and PoA (Proof of
                      Authority).
                    </p>
                    <p>
                      iNode miners receive their rewards in the form of uPow
                      coins. You have the option to set up your own Inode,
                      tailoring its incentive mechanism, to kick off your own
                      inode, or you can become part of an existing Inode within
                      the uPow ecosystem. Take, for instance, Inode1, an image
                      detection Inode. It offers incentives to miners who
                      contribute computational power for the creation of models.
                    </p>
                    <ul>
                      <li>
                        <h3>Layer 1 Staking</h3>
                        <p>
                          uPow is the world’s first blockchain that allows
                          staking on a layer 1 chain and provides rewards. As
                          you know, in typical PoW systems, blocks are generated
                          by miners who receive rewards. However, uPow has a
                          unique consensus mechanism that produces two types of
                          rewards. The first is the block reward, similar to
                          Bitcoin’s. The second is the AI emission, a
                          distinctive feature of uPow. Think of it as earning
                          both a salary and a bonus at work. This means uPow
                          manages to reward both block miners and AI miners, as
                          well as validators and delegates. It’s a win-win-win
                          situation!
                        </p>
                      </li>
                      <li>
                        <h3>No Inflation</h3>
                        <p>
                          Have you ever seen a blockchain that gives rewards for
                          staking without causing inflation? No, right? That’s
                          because uPow’s unique consensus mechanism does it. Our
                          supply is hard-capped at <b>18 million</b>, ensuring
                          no inflation while still providing rewards. It’s like
                          having a wallet that magically refills itself, but
                          without devaluing the currency inside.
                        </p>
                      </li>
                      <li>
                        <h3>DAO (Decentralised Autonomous Organization)</h3>
                        <p>
                          <b>“What the holy smokes? Does uPow have a DAO?”</b>{" "}
                          Yes, indeed! We follow a Delegated Proof of Stake
                          (DPoS) system, where users have the absolute authority
                          to select validators. These validators decide the
                          emissions for iNodes, meaning the community controls
                          the majority of the blockchain. Imagine a democracy
                          where every vote counts, and the elected officials
                          actually listen to the people. uPow is truly
                          community-driven, making it a blockchain by the
                          people, for the people.
                        </p>
                      </li>
                      <li>
                        <h3>iNodes</h3>
                        <p>
                          Now, here’s where the AI part comes in, and it’s also
                          unique from other blockchains. With uPow, anyone can
                          build iNodes (incentive nodes) that perform various
                          tasks based on their design. These tasks can be for AI
                          purposes or anything that does useful work on the
                          blockchain. Imagine a world where your computer isn’t
                          just mining blocks but also helping with medical
                          research or training AI models. That’s the power of
                          iNodes on uPow!
                        </p>
                        <p>
                          In a nutshell, uPow is a groundbreaking blockchain
                          with layer 1 staking, no inflation, a
                          community-driven DAO, and innovative iNodes. It’s like
                          finding a rare Pokémon but in the blockchain world!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <br/>
                <hr className="seperator" />
                <div className="pxp-blog-post-block mt-4 mt-md-5">
                  <h2>History</h2>
                  <div className="mt-3 mt-md-4">
                    <p>
                      The emerging as a brainchild to transform the blockchain
                      technology landscape. It was an era when blockchain was
                      predominantly synonymous with cryptocurrencies, and the
                      potential for broader applications was largely untapped.
                      uPow was conceptualised with the ambition to harness
                      blockchain for practical, real-world applications,
                      steering away from the conventional path of
                      energy-intensive computations with limited tangible
                      output.
                    </p>
                    <div className="pxp-blog-post-blockquote pxp-left">
                      "The team embarked on a journey to develop a unique
                      consensus mechanism, amalgamating the best elements of
                      PoW, DPoS, and PoA. This mechanism was not just about
                      optimising energy consumption but also about incentivising
                      computations that had real-world utility."
                    </div>
                    <p>
                      The year 2024 marked a significant milestone in uPow's
                      journey with the launch of its Layer1 implementation. This
                      launch wasn't just another event in the blockchain world;
                      it was a statement that uPow was ready to redefine the
                      norms of blockchain applications. The response from the
                      blockchain community was overwhelmingly positive,
                      recognising uPow's potential to contribute significantly
                      to diverse fields like AI, data analytics, and beyond.
                    </p>
                    <p>
                      Innovation is the cornerstone of uPow. We constantly
                      challenge the established norms and push the boundaries of
                      blockchain technology. Our commitment is towards
                      integrating blockchain into diverse sectors, ensuring that
                      our technology remains at the forefront of innovation. We
                      believe in a cyclical process of development, feedback,
                      and enhancement, ensuring that uPow continues to lead in
                      blockchain innovation.
                    </p>
                  </div>
                </div>

                <div className="pxp-blog-post-block mt-4 mt-md-5">
                  <h2>Our Vision and Mission</h2>
                  <div className="mt-3 mt-md-4">
                    <p>
                      At uPow, our vision is to pioneer a blockchain revolution
                      that prioritises practical and beneficial computational
                      tasks. Our mission is to build a decentralised network
                      that goes beyond securing digital transactions to making
                      substantial contributions in various sectors like
                      scientific research, machine learning, and environmental
                      studies. By leveraging the collective strength of our
                      Inode network, we are committed to making significant
                      contributions to global knowledge and solutions.
                    </p>
                  </div>
                </div>

                <div className="pxp-blog-post-block mt-4 mt-md-5">
                  <h2>Community and Collaboration</h2>
                  <div className="mt-3 mt-md-4">
                    <p>
                      uPow is not just a technology; it's a community-driven
                      movement. We strongly believe in the power of
                      collaboration and are dedicated to nurturing a dynamic
                      ecosystem of developers, miners, and blockchain
                      enthusiasts. Our community is the backbone of our
                      innovation, offering invaluable insights, feedback, and
                      support. We actively engage with our community through
                      various platforms and events, ensuring an inclusive,
                      diverse, and vibrant network.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUpow;
