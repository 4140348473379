import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/blockchain-animation.json";

function HeroOther() {
  return (
    <div className="pxp-cta-4 pt-200 pb-100">
      {/* <div className="container"> */}
      {/* <div className="row justify-content-center"> */}
      <div className="col-md-8 col-lg-6">
        <div className="text-center hero-other">
          <h2>The renaissance of programmable money.</h2>
          <p className="mt-3 mt-lg-4">
            uPow reimagines the classic UTXO-based scalable technology by
            refining its core infrastructure and virtual machine. This
            enhancement unleashes the technology's considerably untapped
            potential for parallelizable transaction processing and Layer-1
            programmability
          </p>
          <a
            href="https://tradeogre.com/exchange/UPOW-USDT"
            className="pxp-primary-cta text-uppercase mt-3 mt-md-5 pxp-animate"
            target="blank"
          >
            Buy here
          </a>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      <div className="cta-background">
        <Lottie animationData={animationData} />
      </div>
    </div>
  );
}

export default HeroOther;
