import React from "react";
import "../../App.css";
import Hero from "../Hero";
import HomeDiv from "../HomeDiv";
import { AiApps } from "../AiApps";
// import { AiTools } from "../AiTools";
import Comparison from "../Comparison";
import heroImg from "../../img/Ai.png";
import { Helmet } from "react-helmet";
import HeroOther from "../HeroOther";
import Para from "../Para";

export default function Home() {
  return (
    <main className="home">
      <Helmet>
        <title>uPow.ai</title>
        <meta
          name="description"
          content="uPow, which stands for Useful Power of Work, is a Layer 1 blockchain implementation designed for AI applications."
        />
      </Helmet>
      <Hero />
      <div className="topBlur" />
      <div className="bottomBlur" />
      <hr className="seperator" />
      <AiApps />
      <img src={heroImg} alt="" className="middle-img" />
      <hr className="seperator" />
      <HomeDiv />
      {/* <AiTools /> */}
      <HeroOther />
      <hr className="seperator" />
      <Para />
      <hr className="seperator" />
      <Comparison />
    </main>
  );
}
