import React, { useState, useEffect } from "react";
import "../docs.css";
import core from "../img/UPOWFLOW.png";
import reward from "../img/reward.png";
import CopyLinkHeading from "./CopyLinkHeading";

function DocUpow() {
  // console.log(window.innerWidth);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [activeSideClick, setActiveSideClick] = useState("getting-started");
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      // console.log(document.getElementById("doc-content").offsetHeight*0.9);
      //   console.log(document.body.offsetHeight);
      const docHeight =
        document.getElementById("doc-content").offsetHeight * 0.89;
      if (window.scrollY > docHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const scrollToElement = () => {
      const hash = window.location.hash;
      if (hash && contentLoaded) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    scrollToElement();
    window.addEventListener("hashchange", scrollToElement, false);

    return () => {
      window.removeEventListener("hashchange", scrollToElement, false);
    };
  }, [contentLoaded]);

  const handleActiveSideClick = (value) => {
    setActiveSideClick(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => setContentLoaded(true), 600);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className={scrolled ? "side-nav no-fixed" : "side-nav"}>
        {/*<div className="side-nav-search">
          <div className="dropdown search-dropdown">
            <input
              type="text"
              className="form-control"
              name="doc-search"
              id="doc-search"
              placeholder="Search documentation..."
              data-toggle="dropdown"
            />
            <ul
              className="dropdown-menu search-results"
              aria-labelledby="dropdownMenu"
            ></ul>
          </div>
        </div>*/}
        <div className="side-nav-toc">
          <ul>
            <li>
              <a
                href="#getting-started"
                className={
                  activeSideClick === "getting-started" ? "active" : ""
                }
                onClick={() => handleActiveSideClick("getting-started")}
              >
                Initiating Your Journey with uPow
              </a>
              <ul>
                <li>
                  <a
                    href="#what-is-upow"
                    className={
                      activeSideClick === "what-is-upow" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("what-is-upow")}
                  >
                    What is uPow?
                  </a>
                </li>
                <li>
                  <a
                    href="#introduction"
                    className={
                      activeSideClick === "introduction" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("introduction")}
                  >
                    Introduction
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#understanding"
                className={activeSideClick === "understanding" ? "active" : ""}
                onClick={() => handleActiveSideClick("understanding")}
              >
                Understanding the uPow Blockchain Rewards System
              </a>
              <ul>
                <li>
                  <a
                    href="#block-rewards"
                    className={
                      activeSideClick === "block-rewards" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("block-rewards")}
                  >
                    Block Rewards
                  </a>
                </li>
                <li>
                  <a
                    href="#ai-rewards"
                    className={activeSideClick === "ai-rewards" ? "active" : ""}
                    onClick={() => handleActiveSideClick("ai-rewards")}
                  >
                    AI Rewards
                  </a>
                </li>
                <li>
                  <a
                    href="#distribution"
                    className={
                      activeSideClick === "distribution" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("distribution")}
                  >
                    Reward Distribution
                  </a>
                </li>
                <li>
                  <a
                    href="#sharing"
                    className={activeSideClick === "sharing" ? "active" : ""}
                    onClick={() => handleActiveSideClick("sharing")}
                  >
                    Further Sharing of Rewards
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#core-layer"
                className={activeSideClick === "core-layer" ? "active" : ""}
                onClick={() => handleActiveSideClick("core-layer")}
              >
                Core-Layer
              </a>
            </li>
            <li>
              <a
                href="#inode"
                className={activeSideClick === "inode" ? "active" : ""}
                onClick={() => handleActiveSideClick("inode")}
              >
                Inode
              </a>
            </li>
            <li>
              <a
                href="#minerpool"
                className={activeSideClick === "minerpool" ? "active" : ""}
                onClick={() => handleActiveSideClick("minerpool")}
              >
                Minerpool
              </a>
            </li>
            <li>
              <a
                href="#miners"
                className={activeSideClick === "miners" ? "active" : ""}
                onClick={() => handleActiveSideClick("miners")}
              >
                Miners
              </a>
            </li>
            <li>
              <a
                href="#validators"
                className={activeSideClick === "validators" ? "active" : ""}
                onClick={() => handleActiveSideClick("validators")}
              >
                Validators
              </a>
            </li>
            <li>
              <a
                href="#dobby"
                className={activeSideClick === "dobby" ? "active" : ""}
                onClick={() => handleActiveSideClick("dobby")}
              >
                Dobby
              </a>
            </li>
            <li>
              <a
                href="#masterkey"
                className={activeSideClick === "masterkey" ? "active" : ""}
                onClick={() => handleActiveSideClick("masterkey")}
              >
                MasterKey
              </a>
            </li>
            <li>
              <a
                href="#delegates"
                className={activeSideClick === "delegates" ? "active" : ""}
                onClick={() => handleActiveSideClick("delegates")}
              >
                Delegates
              </a>
            </li>
            <li>
              <a
                href="#utxos"
                className={activeSideClick === "utxos" ? "active" : ""}
                onClick={() => handleActiveSideClick("utxos")}
              >
                Utxo's
              </a>
            </li>
            <li>
              <a
                href="#create-wallet"
                className={activeSideClick === "create-wallet" ? "active" : ""}
                onClick={() => handleActiveSideClick("create-wallet")}
              >
                Create Wallet
              </a>
              <ul>
                <li>
                  <a
                    href="#keys"
                    className={activeSideClick === "keys" ? "active" : ""}
                    onClick={() => handleActiveSideClick("keys")}
                  >
                    Public key and Private key
                  </a>
                </li>
                <li>
                  <a
                    href="#wallet"
                    className={activeSideClick === "wallet" ? "active" : ""}
                    onClick={() => handleActiveSideClick("wallet")}
                  >
                    Creating a wallet
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#upow"
                className={activeSideClick === "upow" ? "active" : ""}
                onClick={() => handleActiveSideClick("upow")}
              >
                uPow (Blockchain-Layer/CORE-LAYER)
              </a>
              <ul>
                <li>
                  <a
                    href="#specifications"
                    className={
                      activeSideClick === "specifications" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("specifications")}
                  >
                    Key Specifications
                  </a>
                </li>
                <li>
                  <a
                    href="#installation-steps"
                    className={
                      activeSideClick === "installation-steps" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("installation-steps")}
                  >
                    Installation Steps
                  </a>
                </li>
                <li>
                  <a
                    href="#block-mining"
                    className={
                      activeSideClick === "block-mining" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("block-mining")}
                  >
                    Block Mining
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#ai-mining"
                className={activeSideClick === "ai-mining" ? "active" : ""}
                onClick={() => handleActiveSideClick("ai-mining")}
              >
                Ai Mining software
              </a>
              <ul>
                <li>
                  <a
                    href="#prerequisites"
                    className={
                      activeSideClick === "prerequisites" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("prerequisites")}
                  >
                    Prerequisites
                  </a>
                </li>
                <li>
                  <a
                    href="#installation"
                    className={
                      activeSideClick === "installation" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("installation")}
                  >
                    Installation
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#staking"
                className={activeSideClick === "staking" ? "active" : ""}
                onClick={() => handleActiveSideClick("staking")}
              >
                Staking and Unstaking upow Coins
              </a>
              <ul>
                <li>
                  <a
                    href="#staking-upow"
                    className={
                      activeSideClick === "staking-upow" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("staking-upow")}
                  >
                    Staking upow Coins
                  </a>
                </li>
                <li>
                  <a
                    href="#unstaking"
                    className={activeSideClick === "unstaking" ? "active" : ""}
                    onClick={() => handleActiveSideClick("unstaking")}
                  >
                    Unstaking upow Coins
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#registering"
                className={activeSideClick === "registering" ? "active" : ""}
                onClick={() => handleActiveSideClick("registering")}
              >
                Registering as a Validator on the upow Network
              </a>
              <ul>
                <li>
                  <a
                    href="#reg-validator"
                    className={
                      activeSideClick === "reg-validator" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("reg-validator")}
                  >
                    Registering as a Validator
                  </a>
                </li>
                <li>
                  <a
                    href="#imp"
                    className={activeSideClick === "imp" ? "active" : ""}
                    onClick={() => handleActiveSideClick("imp")}
                  >
                    Important Considerations
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#voting"
                className={activeSideClick === "voting" ? "active" : ""}
                onClick={() => handleActiveSideClick("voting")}
              >
                Voting on the upow Network
              </a>
              <ul>
                <li>
                  <a
                    href="#for-delegates"
                    className={
                      activeSideClick === "for-delegates" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("for-delegates")}
                  >
                    For Delegates
                  </a>
                </li>
                <li>
                  <a
                    href="#for-validators"
                    className={
                      activeSideClick === "for-validators" ? "active" : ""
                    }
                    onClick={() => handleActiveSideClick("for-validators")}
                  >
                    For Validators
                  </a>
                </li>
                <li>
                  <a
                    href="#revoking"
                    className={activeSideClick === "revoking" ? "active" : ""}
                    onClick={() => handleActiveSideClick("revoking")}
                  >
                    Revoking Votes
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#creating"
                className={activeSideClick === "creating" ? "active" : ""}
                onClick={() => handleActiveSideClick("creating")}
              >
                Create an iNode on upow Mainchain
              </a>
            </li>
            <li>
              <a
                href="#iNode-server"
                className={activeSideClick === "iNode-server" ? "active" : ""}
                onClick={() => handleActiveSideClick("iNode-server")}
              >
                Running an iNode
              </a>
            </li>
            <li>
              <a
                href="#iNode-validator"
                className={
                  activeSideClick === "iNode-validator" ? "active" : ""
                }
                onClick={() => handleActiveSideClick("iNode-validator")}
              >
                Running an iNode validator
              </a>
            </li>
            <li>
              <a
                href="#iNode-minerpool"
                className={
                  activeSideClick === "iNode-minerpool" ? "active" : ""
                }
                onClick={() => handleActiveSideClick("iNode-minerpool")}
              >
                Running an iNode Minerpool
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div id="doc-content" className="content">
        <hr id="getting-started" />
        <CopyLinkHeading id="what-is-upow">
          Initiating Your Journey with uPow
        </CopyLinkHeading>
        <h3>What is uPow?</h3>
        <p>
          uPow, which stands for Useful Power of Work, is a Layer 1 blockchain
          implementation designed for AI applications, no pre-sale, closely
          following the original Nakamoto consensus. It's a protocol crafted for
          decentralized inodes. These inodes exist to create decentralized
          Useful work. Each iNode symbolizes a market driven by incentives,
          competing to deliver the best decentralized Usefulwork.
        </p>
        <hr id="introduction" />
        <h3>Introduction </h3>
        <p>
          uPow adopts a distinctive approach to the conventional blockchain.
          Rather than utilizing computational power for solving arbitrary
          puzzles, we channel this power into accomplishing useful tasks. We've
          developed a consensus mechanism that draws inspiration from PoW (Proof
          of Work), DPoS (Delegated Proof of Stake), and PoA (Proof of
          Authority).
        </p>
        <p>
          iNode miners receive their rewards in the form of uPow coins. You have
          the option to set up your own iNode, tailoring its incentive
          mechanism, to kick off your own iNode, or you can become part of an
          existing iNode within the uPow ecosystem. Take, for instance, Inode1,
          an image detection iNode. It offers incentives to miners who
          contribute computational power for the creation of models.
        </p>
        <img src={core} alt="" />
        <hr />

        <div id="understanding">
          <CopyLinkHeading id="understanding">
            Understanding the uPow Blockchain Rewards System
          </CopyLinkHeading>
          <img src={reward} alt="" />
          <p id="block-rewards">
            The uPow blockchain introduces a novel approach to incentivizing
            participants through a dual rewards system, encompassing both Block
            Rewards and AI Rewards. This system is designed to ensure that
            contributions to the blockchain and artificial intelligence tasks
            are equally valued and rewarded.
          </p>

          <h3>Block Rewards</h3>
          <p id="ai-rewards">
            Similar to traditional blockchains, the uPow blockchain rewards
            Block-miners for block creation. This foundational incentive ensures
            the ongoing security and operability of the blockchain.
          </p>

          <h3>AI Rewards</h3>
          <p id="distribution">
            Unique to the uPow blockchain, AI Rewards are distributed for
            contributions to artificial intelligence tasks within the network.
            This incentivizes the use of blockchain resources for advancing AI
            research and applications.
          </p>

          <h3>Reward Distribution</h3>
          <p>
            Reward distribution is governed by the Dobby API, which allocates
            rewards based on the contributions of individual nodes (inodes) and
            their roles within the network. This includes:
          </p>
          <ul>
            <li>
              <strong>To AI Miner Pools:</strong> Inodes distribute a portion of
              their rewards to AI miner pools, which are collectives of AI
              miners working together to increase their chances of earning
              rewards.
            </li>
            <li id="sharing">
              <strong>To Validators:</strong> Inodes also share rewards with
              validators, who are responsible for verifying and confirming
              transactions and blocks on the blockchain.
            </li>
          </ul>

          <h3>Further Sharing of Rewards</h3>
          <p>The rewards are then further distributed as follows:</p>
          <ul>
            <li>
              <strong>AI Miner Pools to Individual AI Miners:</strong> AI miner
              pools distribute their received rewards among their members based
              on each miner's contribution to the pool's efforts.
            </li>
            <li>
              <strong>Validators to Delegates:</strong> Validators share rewards
              with their delegates as a coin of appreciation for their support,
              typically in the form of votes during validator elections.
            </li>
          </ul>

          <p>
            This comprehensive rewards system ensures that every participant,
            from those securing the blockchain to those advancing AI through the
            network, is recognized and rewarded for their contributions. It
            fosters a collaborative and innovative ecosystem that leverages
            blockchain technology for more than just financial transactions.
          </p>
        </div>

        <hr id="core-layer" />
        <CopyLinkHeading id="core-layer">CORE-LAYER</CopyLinkHeading>
        <p>
          The core layer, often called the blockchain layer, forms the backbone
          of the uPow network. Anyone is welcome to host or run this node, thus
          contributing to the uPow network's strength
        </p>
        <p>
          This layer functions as a typical blockchain layer that manages above
          the Inodes and guarantees their efficient operation. It's pivotal in
          keeping the incentive-driven competitive market decentralized, open to
          all without needing special permissions, and safeguarded against
          collusion, ensuring the market remains free from manipulation.
        </p>
        <hr id="inode" />
        <CopyLinkHeading id="inode">INODE</CopyLinkHeading>
        <p>
          An iNode is an incentive node within the uPow framework. You have the
          flexibility to set up your own iNode, customizing its incentive
          structure to initiate your personal iNode. Alternatively, you can join
          an existing iNode within the uPow ecosystem.
        </p>
        <hr id="minerpool" />
        <CopyLinkHeading id="minerpool">MINERPOOL</CopyLinkHeading>
        <p>
          Minerpool, as implied by its name, is the intermediary pool that
          connects inodes and individual miners. The primary role of Minerpool
          is to procure jobs and allocate them among the miners, enabling them
          to securely train or process these jobs.
        </p>
        <hr id="miners" />
        <CopyLinkHeading id="miners">MINERS</CopyLinkHeading>
        <p>
          Miners or trainers form the backbone of the uPow network. These
          individuals dedicate their computational resources to train or process
          useful jobs. They are rewarded based on the efficiency and
          effectiveness of their performance, playing a crucial role in the uPow
          ecosystem by utilizing their resources for meaningful work.
        </p>
        <hr id="validators" />
        <CopyLinkHeading id="validators">VALIDATORS</CopyLinkHeading>
        <p>
          Validators act as the guardians within the uPow network, ensuring that
          both inodes and minerpools carry out their tasks without any
          manipulation or tampering. They have the capability to vote for
          multiple inodes by distributing their votes, with a range from 1 to
          10. Their responsibilities include setting the emission rate of coins
          for each iNode, allocating scores to the minerpools, and validating
          the jobs. This role is crucial in maintaining the integrity and smooth
          functioning of the uPow ecosystem.
        </p>
        <hr id="dobby" />
        <CopyLinkHeading id="dobby">DOBBY</CopyLinkHeading>
        <p>
          Dobby is an API constructed on the uPow blockchain, dedicated to
          storing information about emissions. Whenever a validator votes, this
          action is logged within the Dobby API. This makes it possible for
          anyone to access the API and determine the specific emission
          allocation each iNode should receive per block, without a dobby
          emission a block cannot be mined.
        </p>
        <hr id="masterkey" />
        <CopyLinkHeading id="masterkey">MASTER KEY</CopyLinkHeading>
        <p>
          The Master Key, a pivotal innovation in the uPow ecosystem, emerges as
          a strategic solution to a complex challenge. This key, the public key
          of the genesis block or the inaugural block of the blockchain, is
          vested with extraordinary permissions. It has the unique capability to
          authorise the creation of up to 10,000 blocks without necessitating
          dobby emission, a critical process for mining blocks. Typically, for a
          block to be mined, dobby emission is essential, which in turn depends
          on a delegate voting for a validator, who must then vote on an iNode.
          Given that registering an iNode demands a substantial fee of 1000 uPow
          coins, and without dobby emission, mining cannot proceed, the
          ecosystem faced a significant dilemma. By introducing the Master Key,
          this dilemma is adeptly navigated, ensuring the blockchain's initial
          operations and growth are seamless and uninterrupted, thereby
          showcasing a professional and innovative approach to overcoming
          initial blockchain deployment challenges in the uPow platform.
        </p>
        <hr id="delegates" />
        <CopyLinkHeading id="delegates">DELEGATES</CopyLinkHeading>
        <p>
          These individuals are coin holders who stake their coins in the
          system, gaining voting rights in the process. This allows them to
          participate in the governance of the network by voting for validators.
          Their involvement is crucial in ensuring that the network is managed
          by competent and trustworthy validators, as chosen by the community of
          stakeholders.
        </p>

        <hr id="utxos" />
        <CopyLinkHeading id="utxos">UTXO's</CopyLinkHeading>
        <p>
          UTXOs, or Unspent Transaction Outputs, are a way to keep track of who
          owns what in a blockchain, like keeping track of coins in a digital
          wallet. Imagine you have a piggy bank (your digital wallet) filled
          with coins (UTXOs). When you want to buy something, you break the
          piggy bank and use the exact amount of coins needed for the purchase.
          The shopkeeper gives you back any change, which goes into a new piggy
          bank. In the uPow, when you send cryptocurrency, the network checks
          all your piggy banks (UTXOs) to make sure you have enough coins. It
          then uses these coins to make the payment and creates new UTXOs for
          any change you get back.
        </p>
        <p>
          {" "}
          In a uPow blockchain, UTXOs work similarly. This system helps the
          network track who owns cryptocurrency without needing an account or
          balance. Each transaction generates new UTXOs for the recipients and
          returns any change back to the sender, ensuring all transactions are
          transparent and secure.
        </p>
        <hr id="create-wallet" />
        <CopyLinkHeading id="create-wallet">Create Wallet</CopyLinkHeading>
        <p id="keys">
          The uPow wallet serves as the foundational element of identity
          management, underpinning all activities within the uPow blockchain
          ecosystem. The uPow Blockchain Wallet is a command-line interface
          (CLI) tool designed for interacting with the uPow blockchain. It
          allows users to perform various actions such as creating wallets,
          sending transactions, checking balances, staking, voting, and more.
        </p>
        <h3>Public key and Private key</h3>
        <p id="wallet">
          In the uPow blockchain, the public key is a wallet address shared
          openly for transactions, while the private key is a secure code kept
          confidential, granting wallet access and transaction authorization.
        </p>
        <h3>Creating a wallet</h3>
        <ul>
          <li>1. clone the repo</li>
          <div className="pre">
            <code>git clone https://github.com/upowai/wallet.git</code>
          </div>
          <li>2. Install with python3</li>
          <div className="pre">
            <code>pip3 install -r requirements.txt</code>
          </div>
          <li>3. Create Wallet</li>
          <div className="pre">
            <code>python3 wallet.py createwallet</code>
          </div>
          <li>4. Executing this command will generate and display:</li>
          <div className="pre">
            <code>
              Private key:
              0x8a7f876d477d518f0d1be125c2c4d56251898ca17040d3abb96c6386e9e69a2d
              <br />
              Address: DmmHnvNwLmHjb8YfLcgDRjKAfeZCWM2mtPcVw1A9sifvg
            </code>
          </div>
          <li>5. Sending uPow</li>
          <div className="pre">
            <code>
              python3 wallet.py send -to [recipient_address] -a [amount]
            </code>
          </div>
          <li>5. Checking Balance</li>
          <div className="pre">
            <code>python3 wallet.py balance</code>
          </div>
          <br />
          <p>
            Please note that all wallets created are stored locally in the
            `key_pair_list.json` file, where you can access your private and
            public keys at any time.
          </p>
        </ul>
        <hr id="upow" />
        <div className="upow-readme">
          <CopyLinkHeading id="upow">
            uPow (Blockchain-Layer/CORE-LAYER)
          </CopyLinkHeading>

          <h3 id="specifications">Key Specifications</h3>
          <ul>
            <li>
              <strong>Maximum Supply:</strong> The total supply of uPow is
              capped at 18,884,643 units, ensuring a deflationary model to
              preserve value over time.
            </li>
            <li>
              <strong>Decimal Precision:</strong> uPow supports up to 8 decimal
              places, allowing for micro-transactions and enhancing user
              flexibility in transaction amounts.
            </li>
            <li>
              <strong>Block Generation:</strong> Blocks in the uPow blockchain
              are generated approximately every minute, striking a balance
              between transaction confirmation times and network efficiency.
            </li>
            <li>
              <strong>Block Size Limit:</strong> Each block has a maximum size
              limit of 2MB, optimizing the network’s throughput while
              maintaining speed and reliability.
            </li>
            <li>
              <strong>Transactions Per Block:</strong> Given the average
              transaction size of 250 bytes, with a typical structure of 5
              inputs and 2 outputs, a single block can accommodate approximately
              8300 transactions.
            </li>
            <li id="installation-steps">
              <strong>Transaction Throughput:</strong> The network is designed
              to handle approximately 40 transactions per second, ensuring
              scalability and performance for users.
            </li>
          </ul>

          <h3>Installation Steps</h3>
          <ul>
            <li>1. Clone uPow repo</li>
            <p>
              <div className="pre">
                <code>git clone https://github.com/upowai/upow.git</code>
              </div>
            </p>
            <li>2. Install Python 3.8.9 </li>
            <p>
              The uPow blockchain requires Python version 3.8.9. Use the
              provided Makefile to automate the installation process.
            </p>

            <div className="pre">
              <code>cd upow </code>
              <br />
              <code>make -f makefile.python3.8.9 install_python3.8.9</code>
            </div>

            <li>3. Install PostgreSQL 14</li>
            <p>
              PostgreSQL 14 is required for the database component of the uPow
              blockchain. Use the second Makefile to install and start
              PostgreSQL.
            </p>

            <div className="pre">
              <code>make -f makefile.postgres all</code>
            </div>

            <li>4. Database Setup</li>
            <p>
              After installing the required software, run the{" "}
              <code>db_setup.sh</code> script to create the uPow database and
              configure PostgreSQL.
            </p>

            <div className="pre">
              <code>chmod +x db_setup.sh ./db_setup.sh</code>
            </div>

            <p>Then, to start the node:</p>

            <div className="pre">
              <code>pip install -r requirements.txt</code>
              <br id="block-mining" />
              <code>uvicorn upow.node.main:app --port 3006</code>
            </div>
          </ul>

          <h3>Block Mining</h3>
          <ul>
            <p>
              Block hash algorithm is sha256. The block sha256 hash must start
              with the last difficulty hex characters of the previously mined
              block. difficulty can also have decimal digits, that will restrict
              the difficulty + 1th character of the derived sha to have a
              limited set of values.
            </p>
            <p>Running a Block Miner</p>
            <div className="pre">
              <code>python3 miner.py &lt;wallet_address&gt; </code>
            </div>
          </ul>
        </div>

        <hr id="ai-mining" />
        <CopyLinkHeading id="ai-mining">Ai Mining software</CopyLinkHeading>
        <p id="prerequisites">
          Ai Miner is a sophisticated Python-based mining client designed for
          participating in a distributed computing network. It connects to a
          mining pool, downloads computational jobs, processes them, and submits
          the results. This guide provides instructions on how to set up and run
          Miner.
        </p>
        <h3>Prerequisites</h3>
        <p>
          Before you begin, make sure you have the following software installed
          on your computer:
        </p>
        <ul>
          <li>Python 3.10 or higher</li>
          <li>git</li>
          <li>pip for installing Python packages</li>
          <li id="installation">An active internet connection</li>
        </ul>
        <h3>Installation</h3>
        <ol>
          <li>Clone the Repository</li>
          <p>Clone the Miner repository to your local machine using:</p>
          <div className="pre">
            <code> git clone https://github.com/upowai/miner.git</code>
          </div>

          <li>Install Dependencies</li>
          <p>
            Navigate to the cloned directory and install the required Python
            packages:
          </p>
          <div className="pre">
            <code>pip3 install -r requirements.txt</code>
          </div>

          <li>Configuration</li>
          <p>Miner requires specific command-line arguments to start:</p>
          <ul>
            <li>
              <strong>--MINER_POOL_IP:</strong> The IP address of the miner
              pool.
            </li>
            <li>
              <strong>--MINER_POOL_PORT:</strong> The port number of the miner
              pool.
            </li>
            <li>
              <strong>--WALLET_ADDRESS:</strong> Your wallet address for
              receiving mining rewards.
            </li>
          </ul>

          <li>Usage</li>
          <p>To run Miner, use the following command:</p>
          <div className="pre">
            <code>
              python3 miner.py --MINER_POOL_IP "127.0.0.1" --MINER_POOL_PORT
              5501 --WALLET_ADDRESS "your_wallet_address"
            </code>
          </div>
          <p>
            Replace "127.0.0.1", 5501, and "your_wallet_address" with the
            appropriate miner pool IP, port, and your wallet address.
          </p>
        </ol>
        <hr id="staking" />
        <div>
          <CopyLinkHeading id="staking">
            Staking and Unstaking upow Coins
          </CopyLinkHeading>
          <p id="staking-upow">
            Staking and unstaking are important actions within the upow network
            that affect your participation and potential rewards. Always ensure
            you are making informed decisions by consulting the latest network
            guidelines and rules. Here's how you can manage your stake:
          </p>
          <h3>Staking upow Coins</h3>
          <p>
            To stake upow coins, you will need to execute a command with the
            amount you wish to stake. This process delegates your coins to the
            network, allowing you to participate as a delegate.
          </p>
          <div className="pre">
            <code>python3 wallet.py stake -a &lt;amount&gt;</code>
          </div>
          <p id="unstaking">
            Replace <code>&lt;amount&gt;</code> with the number of upow coins
            you wish to stake. Ensure you have enough coins in your wallet
            before proceeding.
          </p>

          <h3>Unstaking upow Coins</h3>
          <p>
            To unstake your upow coins and withdraw your stake from the network,
            use the following command:
          </p>
          <div className="pre">
            <code>python3 wallet.py unstake</code>
          </div>
          <p>
            Note: There may be a waiting period for unstaking to process based
            on the network's rules. Ensure you are aware of these conditions
            before unstaking.
          </p>
        </div>
        <hr id="registering" />
        <div>
          <CopyLinkHeading id="registering">
            Registering as a Validator on the upow Network
          </CopyLinkHeading>
          <p>
            Becoming a validator is a significant commitment and an important
            role within the upow network. Validators are responsible for
            verifying transactions and maintaining the integrity of the network.
            Follow these steps to register as a validator:
          </p>
          <ol>
            <li>
              <strong>Stake upow Coins:</strong> Before you can register as a
              validator, you must first stake upow coins and become a delegate.
              This demonstrates your commitment to the network's security.
            </li>
            <li>
              <strong>Register as a Validator:</strong> After becoming a
              delegate, you can proceed to register as a validator. Note that
              the registration fee is non-refundable.
            </li>
            <li id="reg-validator">
              <strong>Participate in the Network:</strong> As a validator, you
              can cast votes on network decisions but only towards iNodes. You
              cannot vote for yourself.
            </li>
          </ol>
          <h3>Registering as a Validator</h3>
          <p>
            To register as a validator, ensure you have completed the staking
            process to become a delegate. Then, use the following command:
          </p>
          <div className="pre">
            <code>python3 wallet.py register_validator</code>
          </div>
          <p id="imp">
            This action will register your node as a validator in the upow
            network. Remember, the registration fee you pay at this stage is not
            refundable.
          </p>

          <h3>Important Considerations</h3>
          <ul>
            <li>
              The role of a validator is crucial for the network's health and
              security. Ensure you understand the responsibilities before
              registering.
            </li>
            <li>
              Validator registration fees are non-refundable. This is to prevent
              spam registrations and ensure that only serious participants
              become validators.
            </li>
            <li>
              As a validator, you can support the network by voting on various
              iNode based on Range from 1-10. However, your vote is limited to
              iNodes.
            </li>
          </ul>

          <p>
            By following these steps and considerations, you can contribute to
            the upow network's security and governance as a validator. Ensure
            you are prepared for the responsibilities and have a clear
            understanding of the network's rules and requirements.
          </p>
        </div>
        <hr id="voting" />
        <div>
          <CopyLinkHeading id="voting">
            Voting on the upow Network
          </CopyLinkHeading>
          <p id="for-delegates">
            Voting is a pivotal action within the upow network, allowing
            delegates and validators to influence decisions and governance.
            Here's how the voting process works:
          </p>
          <h3>For Delegates</h3>
          <p>
            Delegates can vote for validators within a specified range from 1 to
            10. The amount of staked coins directly influences their voting
            power. For example, if a delegate stakes 1000 coins and allocates a
            voting range of 5 to a validator, it signifies that 500 of their
            staked coins support that validator.
          </p>
          <div className="pre">
            <code>
              python3 wallet.py vote -r &lt;range&gt; -to &lt;recipient&gt;
            </code>
          </div>
          <p id="for-validators">
            Replace <code>&lt;range&gt;</code> with your desired voting power
            and <code>&lt;recipient&gt;</code> with the validator's address.
          </p>

          <h3>For Validators</h3>
          <p>
            Validators, once registered, can cast votes towards iNodes based on
            their total stake from delegate. This process is similar to delegate
            voting but is specifically aimed at supporting network
            infrastructure and governance.
          </p>
          <div className="pre">
            <code>
              python3 wallet.py vote -r &lt;range&gt; -to &lt;recipient&gt;
            </code>
          </div>
          <p id="revoking">
            Replace <code>&lt;range&gt;</code> with your desired voting power
            and <code>&lt;recipient&gt;</code> with the iNode's address.
          </p>
          <h3>Revoking Votes</h3>
          <p>
            Both delegates and validators can revoke their votes if needed. This
            action requires specifying from whom the vote is being revoked.
          </p>
          <div className="pre">
            <code>python3 wallet.py revoke -from &lt;revoke_from&gt;</code>
          </div>
          <p>
            Replace <code>&lt;revoke_from&gt;</code> with the address from which
            you wish to revoke your vote.
          </p>
        </div>

        <hr id="creating" />
        {/*  */}
        <div>
          <CopyLinkHeading id="creating">
            Create an iNode on upow Mainchain
          </CopyLinkHeading>
          <p>
            Before creating your first iNode on the upow mainchain, it's
            important to follow these steps in order:
          </p>
          <ul>
            <li>Develop the iNode mechanism.</li>
            <li>Test your iNode locally.</li>
            <li>Stake upow coins to become a delegate.</li>
            <li>Create an iNode on the upow mainchain.</li>
            <li>
              Only after completing the above steps, proceed to create an iNode
              on the upow mainchain.
            </li>
          </ul>
          <br />
          <ol>
            <li>
              <p>Step 1: Acquire upow Coins</p>
              <span>
                To register an iNode on the upow main net, you will first need
                upow coins. Current Price for Registration is 1000 upow coins
              </span>
            </li>

            <li>
              <p>Step 2: Stake upow Coins to Become a Delegate</p>
              <span>
                Before registering your iNode, you must stake upow coins to
                become a delegate. This is a crucial step to participate in the
                network.
              </span>
            </li>
            <li>
              <p>Step 3: Register the iNode</p>
              <span>
                Registering an iNode costs upow coins, which are returned upon
                deregistration of the iNode.
              </span>
            </li>
            <div className="pre">
              <code>
                python3 wallet.py register_inode
                <br />
                &gt;&gt; Select wallet (default):
                <br />
                &gt;&gt; Transaction pushed...
                <br />✅ iNode Registered successfully
              </code>
            </div>
            <p>
              Note: Ensure that you have the necessary amount of upow coins in
              your wallet before attempting to register your iNode. The
              registration process involves a network transaction that will be
              validated and recorded on the upow blockchain.
            </p>
            <li>Step 4: Deregister the iNode</li>
            <p>
              If you wish to deregister your iNode and recover your upow coins,
              use the following command:
            </p>
            <div className="pre">
              <code>
                python3 wallet.py de_register_inode
                <br />
                &gt;&gt; Select wallet (default):
                <br />
                &gt;&gt; Transaction pushed...
                <br />✅ iNode deregistered successfully
              </code>
            </div>
          </ol>
        </div>
        <hr id="iNode-server" />
        {/*  */}

        <CopyLinkHeading id="iNode-server">Running an iNode</CopyLinkHeading>
        <p>
          <strong>iNode</strong> is a Python-based application designed for
          managing and processing jobs in a distributed network. It leverages
          MongoDB, Redis, and external APIs for efficient job assignment,
          validator updates, and miner management. The server incorporates a
          FastAPI web server to handle HTTP requests.
        </p>
        <div>
          <br />
          <p>Please vist the github repo for more detailed information</p>
          <a
            href="https://github.com/upowai/inode"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/upowai/inode
          </a>
        </div>
        <hr id="iNode-validator" />

        {/*  */}

        <CopyLinkHeading id="iNode-validator">
          Running an iNode validator
        </CopyLinkHeading>
        <p>
          <strong>Validator</strong> is a Python-based application designed for
          running a validator node within the Upow blockchain network.
          Validators are essential to the Upow ecosystem, ensuring the integrity
          and proper functioning of inodes and minerpools by validating jobs,
          setting emission rates, and scoring minerpools.
        </p>
        <div>
          <br />
          <p>Please vist the github repo for more detailed information</p>
          <a
            href="https://github.com/upowai/validator"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/upowai/validator
          </a>
        </div>
        <hr id="iNode-minerpool" />

        {/*  */}

        <CopyLinkHeading id="iNode-minerpool">
          Running an iNode Minerpool
        </CopyLinkHeading>
        <p>
          <strong>MinerPool</strong> serves as a crucial intermediary between
          inodes and individual Ai-miners within a distributed computing
          environment. Its primary function is to procure computational jobs
          from inodes and efficiently distribute them among participating
          miners. This enables miners to securely engage in training or
          processing these jobs, contributing to the network's overall
          computational power and integrity.
        </p>
        <div>
          <br />
          <p>Please vist the github repo for more detailed information</p>
          <a
            href="https://github.com/upowai/minerpool"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/upowai/minerpool
          </a>
        </div>
        <hr />

        {/*  */}
      </div>
    </div>
  );
}

export default DocUpow;
