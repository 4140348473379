import React from "react";
import "../../App.css";
import AboutUpow from "../AboutUpow";
import { Helmet } from "react-helmet";

export default function Learn() {
  return (
    <main className="learn">
      <Helmet>
        <title>Learn - uPow.ai</title>
        <meta
          name="description"
          content="uPow, which stands for Useful Power of Work, is a Layer1 implementation, no pre-sale, closely following the original Nakamoto consensus."
        />
      </Helmet>
      <div className="topBlur" />
      <div className="background-learn-right" />
      <AboutUpow />
      <div className="bottomBlur" />
    </main>
  );
}
