import React from "react";
import { IoLogoGithub } from "react-icons/io";
import { SiDiscord } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="pxp-footer pt-100 pb-100 mt-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="pxp-footer-logo">
                uPow
                {/* <span>
                  <b>.</b>ai
                </span> */}
              </div>
              <div className="pxp-footer-address mt-2">contact@upow.ai</div>
              <div className="pxp-footer-social mt-2">
                <a
                  href="https://discord.gg/f2Vy5SpdB2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <SiDiscord />
                  </span>
                </a>
                <a
                  href="https://github.com/upowai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <IoLogoGithub />
                  </span>
                </a>
                <a
                  href="https://twitter.com/Upow_ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <FaXTwitter />
                  </span>{" "}
                  {/* Assuming FaTwitter is the correct icon */}
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-lg-8">
              <div className="row">
                <div className="col-6 col-md-4">
                  <h4 className="pxp-footer-header mt-4 mt-lg-0">
                    Quick links
                  </h4>
                  <ul className="list-unstyled pxp-footer-links mt-2">
                    <li>
                      <Link to="https://wallet.upow.ai/" target="_blank">
                        Wallet
                      </Link>
                    </li>
                    <li>
                      <Link to="/learn">Learn</Link>
                    </li>
                    <li>
                      <a
                        href="mailto:contact@upow.ai"
                        rel="noopener noreferrer"
                      >
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-sm-4 col-md-4"> */}
                <div className="col-6 col-md-4">
                  <h4 className="pxp-footer-header mt-4 mt-lg-0">Actions</h4>
                  <ul className="list-unstyled pxp-footer-links mt-2">
                    <li>
                      <a
                        href="https://www.coinmarketcap.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Coin MarkertCap
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.coingecko.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Coingecko
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.livecoinwatch.com/price/uPowai-UPOW"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Livecoinwatch
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-4">
                  <h4 className="pxp-footer-header mt-4 mt-lg-0">Explore</h4>
                  <ul className="list-unstyled pxp-footer-links mt-2">
                    <li>
                      <Link to="/docs">Docs</Link>
                    </li>
                    <li>
                      <a
                        href="https://explorer.upow.ai"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.upow.ai"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Api
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="pxp-footer-bottom mt-2">
            <div className="pxp-footer-copyright">
              &copy; uPow. All Rights Reserved. {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
