import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/upow_log.png";
// import btclogo from "../img/btc.png";
import btclogo from "../img/btc-light.png";
import ltclogo from "../img/ltc-other.png";

function Comparison() {
  return (
    <section className="comparison">
      <div className="container mt-100">
        <h2 className="pxp-section-h2 text-center">Quick Comparison</h2>

        <div className="row mt-5">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/" className="pxp-plans-1-item">
              <div className="pxp-plans-1-item-fig">
                <img
                  src={btclogo}
                  alt="Bitcoin Logo"
                  style={{ width: "190px", height: "auto" }}
                />
              </div>
              <div className="pxp-plans-1-item-title">Bitcoin</div>
              <ul className="pxp-plans-1-item-features list-unstyled">
                <li>No L1 Scaling</li>
                <li>1MB Block size</li>
                <li>7 txnx per second</li>

                <li>21M Supply</li>
              </ul>
              <div className="pxp-plans-1-item-price">
                {/* <span className="pxp-plans-1-item-price-currency">$</span> */}
                <span className="pxp-plans-1-item-price-sum">10</span>
                <span className="pxp-plans-1-item-price-period">
                  minutes block
                </span>
              </div>
              {/* <div className="pxp-plans-1-item-cta text-uppercase">
                Know More
              </div> */}
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/" className="pxp-plans-1-item pxp-is-popular">
              <div className="pxp-plans-1-item-label">Popular</div>
              <div className="pxp-plans-1-item-fig">
                <img
                  src={Logo}
                  alt="uPow Logo"
                  style={{ width: "190px", height: "auto" }}
                />
              </div>
              <div className="pxp-plans-1-item-title">uPow</div>
              <ul className="pxp-plans-1-item-features list-unstyled">
                <li>Unbounded L1 Scaling</li>
                <li>2MB Block size</li>
                <li>40+ txnx per second</li>

                <li>19M Supply</li>
              </ul>
              <div className="pxp-plans-1-item-price">
                {/* <span className="pxp-plans-1-item-price-currency">$</span> */}
                <span className="pxp-plans-1-item-price-sum">60</span>
                <span className="pxp-plans-1-item-price-period">
                  seconds block
                </span>
              </div>
              {/* <div className="pxp-plans-1-item-cta text-uppercase">
                Know More
              </div> */}
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/" className="pxp-plans-1-item">
              <div className="pxp-plans-1-item-fig">
              <br/>
                <img
                  src={ltclogo}
                  alt="LTC Logo"
                  style={{ width: "162px", height: "auto" }}
                />
              </div>
              <div className="pxp-plans-1-item-title">LTC</div>
              <ul className="pxp-plans-1-item-features list-unstyled">
                <li>No L1 Scaling</li>
                <li>1 MB Block size</li>
                <li>33 txnx per second</li>

                <li>42M Supply</li>
              </ul>
              <div className="pxp-plans-1-item-price">
                {/* <span className="pxp-plans-1-item-price-currency">$</span> */}
                <span className="pxp-plans-1-item-price-sum">2.5</span>
                <span className="pxp-plans-1-item-price-period">
                  minutes block
                </span>
              </div>
              {/* <div className="pxp-plans-1-item-cta text-uppercase">
                Know More
              </div> */}
            </Link>
          </div>
        </div>
      </div>
      <div className="background-blur-right" alt=""></div>
    </section>
  );
}

export default Comparison;
