import React from "react";
import { FiLink } from "react-icons/fi";

const CopyLinkHeading = ({ children, id }) => {
  const handleCopyLink = () => {
    const url = `${window.location.origin}${window.location.pathname}#${id}`;

    navigator.clipboard.writeText(url).then(
      () => {
        alert("Link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <hr id={id} />
      <h2>{children}</h2>
      <FiLink style={{ cursor: "pointer" }} onClick={handleCopyLink} />
    </div>
  );
};

export default CopyLinkHeading;
