import React from "react";
import "animate.css";
import Lottie from "lottie-react";
import blueRobot from "../assets/blue-robot.json";

function Hero() {
  return (
    <div className="hero-container pxp-hero pxp-hero-small vh-50">
      <div className="hero pxp-hero-caption pxp-hero-caption-bottom-left pxp-is-small container animate__animated animate__fadeInLeft">
        <p className="hero-top">GO GLOBAL WITH</p>
        <h1 className="main-heading">
          DECENTRALISED AI
          <br /> COMPUTING POWER
        </h1>
        <p className="hero-bottom mb-0">
          Discover the powerful network, unleash the potential of supercomputing
          AI with the groundbreaking - <b> Useful Power of Work (uPow)</b> -
          algorithm revolutionizing problem solving like never before!
        </p>
      </div>
      <div className="hero-img">
        <Lottie animationData={blueRobot} />
      </div>
    </div>
  );
}

export default Hero;
