import React from "react";
import cloudSix from "../img/cloudsix.png";

function HomeDiv() {
  return (
    <div className="homeDiv-container">
      <div className="containerHm">
        <img src={cloudSix} alt="AI Cloud" className="aboutImage" />
        <ul className="aboutItems">
          <li className="aboutItem">
            {/* <img src={getImageUrl("about/cursorIcon.png")} alt="Cursor icon" /> */}
            <div className="aboutItemText">
              <h2>Blockchain-Enabled Distributed GPU Machine Learning</h2>
              <p>
                Leveraging blockchain for decentralized GPU resources to enhance
                machine learning efficiency and scalability.
              </p>
            </div>
          </li>
          <li className="aboutItem">
            {/* <img src={getImageUrl("about/serverIcon.png")} alt="Server icon" /2 */}
            <div className="aboutItemText">
              <h2>Decentralized Machine Learning Model Refinement</h2>
              <p>
                Empowering model refinement and accuracy through a
                decentralized, blockchain-based machine learning ecosystem.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HomeDiv;
