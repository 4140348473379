import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Button from "./Button";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { AiOutlineExport } from "react-icons/ai";
// import AiLogo from "../img/ai-logo.png";
// import ULogo from '../img/U-crypto-logo.png';

function Navbar() {
  var path = window.location.pathname;
  const [activeClick, setActiveClick] = useState(path);
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [sidebarClicked, setSideBarClicked] = useState(false);

// console.log(path);
  const handleActiveClick = (path) => {
    // console.log(path);
    setActiveClick(path);
  };
  const handleClick = () => {
    setClick(!click);
  };

  const customSidebar = {
    display: "flex",
    
  };
  // const customButton = {
  //   display: "inline-block",
  // };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header>
      <nav className={scrolled ? "navbar scrolled" : "navbar"}>
        <span className="logo">
          <Link
            to="/"
            className={activeClick === "/" ? null : null}
            onClick={() => handleActiveClick("/")}
          >
            {/* <img src={AiLogo} alt="" /> */}
            uPow
          </Link>
        </span>
        <ul
          className="navigation"
          style={click !== sidebarClicked ? customSidebar : {}}
        >
          <li>
            <Link
              to="/"
              className={activeClick === "/" ? "items active" : "items"}
              onClick={() => {
                handleActiveClick("/");
                setSideBarClicked(!sidebarClicked);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/learn"
              className={activeClick === "/learn" ? "items active" : "items"}
              onClick={() => {
                handleActiveClick("/learn");
                setSideBarClicked(!sidebarClicked);
              }}
            >
              Learn
            </Link>
          </li>
          {/* <li>
            <Link
              to="/use"
              className={activeClick === "/use" ? "items active" : "items"}
              onClick={() => {
                handleActiveClick("/use");
                setSideBarClicked(!sidebarClicked);
              }}
            >
              Use
            </Link>
          </li> */}
          <li>
            <Link
              to="/docs"
              className={activeClick === "/docs" ? "items active" : "items"}
              onClick={() => {
                handleActiveClick("/docs");
                setSideBarClicked(!sidebarClicked);
              }}
            >
              Docs
            </Link>
          </li>
          <li>
            <Link
              to="https://wallet.upow.ai/"
              className={activeClick === "/wallet" ? "items active" : "items"}
              onClick={() => {
                handleActiveClick("/wallet");
                setSideBarClicked(!sidebarClicked);
              }}
            >
              Wallet <AiOutlineExport />
            </Link>
          </li>
          <li>
            <Button
              className={activeClick === "/" ? "now" : "now"}
              onClick={() => {
                handleActiveClick("/");
                setSideBarClicked(!sidebarClicked);
              }}
            />
          </li>
          {/* <li>
            <Button
              className={activeClick === "home" ? "now" : "now"}
              onClick={() => {
                handleActiveClick("home");
                setSideBarClicked(!sidebarClicked);
              }}
              style={
                click
                  ? customButton
                  : sidebarClicked
                  ? customButton
                  : { display: "none" }
              }
            />
          </li> */}
        </ul>
        <div className="extra"></div>
        <div className="menu-icon" onClick={handleClick}>
          {click !== sidebarClicked ? <IoClose /> : <IoMenu />}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
