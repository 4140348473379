import React from "react";
import Navbar from "./Components/Navbar";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Learn from "./Components/Pages/Learn";
// import Academia from "./Components/Pages/Academia";
import UpowDocs from "./Components/Pages/UpowDocs";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <div>
      <Router>
      <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn" element={<Learn />} />
          {/* <Route path="/academia" element={<Academia />} /> */}
          <Route path="/docs" element={<UpowDocs />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
