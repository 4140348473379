import React from "react";
import "./Button.css";
import { AiOutlineExport } from "react-icons/ai";

function Button(props) {
  return (
    <a
      href="https://explorer.upow.ai"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        className={props.className}
        onClick={props.onClick}
        style={props.style}
      >
        Explorer {" "}<AiOutlineExport />
      </button>
    </a>
  );
}

export default Button;
